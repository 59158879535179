import React from "react"
import { Container, Box, Heading } from "@chakra-ui/react"
import { css } from "@emotion/react"

type Props = {
  title?: SVGStringList
  paddingTop: number
  paddingBottom: number
  paddingMobileTop: number
  paddingMobileBottom: number
}

const Title: React.FC<Props> = ({ title = "", paddingTop, paddingBottom, paddingMobileTop, paddingMobileBottom }) => {
  const sectionSpacing = css`
    padding-top: ${paddingMobileTop || 0}px;
    padding-bottom: ${paddingMobileBottom || 0}px;
    @media (min-width: 768px) {
      padding-top: ${paddingTop || 0}px;
      padding-bottom: ${paddingBottom || 0}px;
    }
  `

  return (
    <Container maxW="md" px={[6, 4]} css={sectionSpacing}>
      <Box as="section" py={[4, 8]} textAlign="center">
        <Heading as="h1" size="h1">
          {title || "Title"}
        </Heading>
      </Box>
    </Container>
  )
}

export default React.memo(Title)
