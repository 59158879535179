import React from "react"
import { Container, Box, Button, Flex } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { useCore } from "@app/hooks/useCore"
import Link from "@app/components/Link"
import type { Props } from "@app/pages/404"
import type { PageProps } from "@root/types/global"
import Title from "@app/components/Sections/Title/Title"
import monogram from "../../public/images/monogram.png"

const Error: React.FC<PageProps<Props>> = ({ page }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const content = useContent(page)

  return isBrowser ? (
    <Box py={24}>
      <Title title={page?.title} />
      <Container maxW="md" pb={16}>
        <Box as="section" pb={20} textAlign="center">
          <Box mb={8}>{content}</Box>
          <Button size="sm" as={Link} to="/">
            Return Home
          </Button>
        </Box>
        <Flex align="center" justify="center" pb={10}>
          <img width={"60px"} src={monogram} />
        </Flex>
      </Container>
    </Box>
  ) : null
}

export default Error
